#header .header1 {
    background: url("../../assets/banner_topo.png") no-repeat center center;
    background-size: cover;
    height: 90vh;
}

#header .header1 .container {
    height: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

#header .header1 .container .top {
    display: flex;
    align-items: center;
    justify-content: space-between;
    position: absolute;
    top: 0;
    width: 80%;
}

#header .header1 .container .top .logo {
    width: 20%;
}

#header .header1 .container .top .cta {
    margin-left: auto;
}

#header .header1 .container .top .cta #ctaWpp figure {
    width: 10%;
}

#header .header1 .container .bottom {
    display: flex;
    flex-direction: column;
    gap: 2rem;
    margin-top: 6rem;
}

#header .header1 .container .bottom .title {
    font-weight: 400;
    width: 50%;
    text-align: left;
}

#header .header1 .container .bottom .title span {
    font-weight: 1000;
}

#header .header1 .container .bottom .text {
    color: #6E7889;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
    width: 50%;
}

#header .header1 .container .bottom #ctaWpp {
    width: 40%;
}

#header .header1 .container .bottom #ctaWpp figure {
    width: 6%;
}

#header .header2 {
    background-color: #7783B1;
    color: white;
    font-weight: 1000;
}

#header .header2 .container {
    text-align: center;
    padding: 1rem 0;
}

#header .header2 .container p {
    font-size: 30px;
}

#header .header3 .container {
    display: flex;
    flex-direction: column;
    gap: 2rem;
}

#header .header3 .container .gallery {
    display: flex;
    justify-content: center;
    gap: 2rem;
}

#header .header3 .container .gallery .item {
    display: flex;
    flex-direction: column;
    gap: .5rem;
    align-items: center;
}

#header .header3 .container .gallery .item figure {
    max-width: 154px;
}

#header .header3 .container .gallery .item p {
    color: #3D4C86;
    font-weight: 1000;
}

#header .header3 .container .text-bottom {
    color: #6E7889;
    text-align: center;
    font-style: normal;
    font-weight: 400;
    line-height: normal;
}

#header .header3 .container #ctaWpp {
    margin: 0 auto;
    width: 40%;
}

#header .header3 .container #ctaWpp figure {
    width: 6%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #header .header1 {
        background: url("../../assets/mobile/banner_topo.png") no-repeat center center;
        background-size: cover;
        height: 100vh;
    }

    #header .header1 .container {
        justify-content: flex-start;
    }

    #header .header1 .container .top {
        left: 0;
        width: 95%;
    }

    #header .header1 .container .top .logo {
        width: 40%;
    }

    #header .header1 .container .top .cta #ctaWpp figure {
        width: 12%;
    }

    #header .header1 .container .bottom {
        margin-top: 8rem;
        height: 100%;
    }

    #header .header1 .container .bottom .title {
        text-align: center;
        width: 100%;
    }

    #header .header1 .container .bottom .text {
        margin-top: auto;
        width: 100%;
        text-align: center;
    }

    #header .header1 .container .bottom #ctaWpp {
        width: 100%;
    }

    #header .header3 .container .gallery {
        flex-wrap: wrap;
    }

    #header .header3 .container #ctaWpp {
        margin: 0 auto;
        width: 100%;
    }

}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #header .header1 {
        height: 80vh;
    }

    #header .header1 .container .top {
        width: 98%;
    }

    #header .header1 .container .top .logo {
        width: 48%;
    }

    #header .header2 .container p {
        font-size: 18px;
    }

    #header .header3 .container .gallery .item {
        width: 100%;
    }
}