#form {
    color: white;
    border-radius: 10px;
    width: 60%;
    margin: 1rem auto 0;
}

#form .form-div1 {
    display: flex;
    flex-direction: column;
    gap: .5rem;
}

#form .form-div1 label {
    margin-top: .3rem;
}

#form .form-div1 span {
    color: red;
}

#form .form-div1 input,
#form .form-div1 textarea {
    border-radius: 5px;
    padding: .5rem 5px;
    background-color: #F7FAFD;
    border: 1px solid rgba(0, 0, 0, .2);
    resize: none;
}

#form .form-div2 .checkbox {
    margin-top: 1rem;
}

#form .form-div2 .checkbox label {
    margin-left: .5rem;
}

#form .form-div2 .checkbox button {
    background-color: #6EC762;
    cursor: pointer;
    margin-top: .5rem;
    width: 100%;
    text-transform: uppercase;
    color: white;
    font-size: 1.3rem;
    padding: 1rem 0;
    border-radius: 5px;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
    #form {
        width: 80%;
    }

    #form .form-div1 input,
    #form .form-div1 textarea {
        width: 100%;
    }

    #form .form-div2 .checkbox {
        text-align: left;
    }

    #form .form-div2 .checkbox button {
        width: 100%;
    }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
    #form {
        width: 95%;
    }

    #form .form-div2 .checkbox button {
        font-size: 1rem;
        padding: .7rem 0;
    }
}