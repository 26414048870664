#formPage {
  background-color: #7783B1;
}

#formPage .container {
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

#formPage .container .title {
  color: white;
}

#formPage .container .text {
  color: #C5D1FF;
  font-size: 24px;
  text-align: center;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {}