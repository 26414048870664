#about .about1 {
  background-color: #F0F6FF;
}

#about .about1 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-top: 1px solid #7783B1;
}

#about .about1 .container .text {
  text-align: center;
  color: #35406D;
}

#about .about1 .container .gallery {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  grid-template-rows: repeat(2, 1fr);
  gap: 1rem;
  justify-content: center;
}

#about .about1 .container .gallery .item {
  position: relative;
  background-color: #FFFFFF;
  border-radius: 0 0 7px 7px;
  min-width: 100%;
  padding-bottom: 5rem;
}

#about .about1 .container .gallery .item .bottom {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1rem .5rem;
}

#about .about1 .container .gallery .item .bottom .title {
  font-size: 24px;
}

#about .about1 .container .gallery .item .bottom .text_card {
  color: #6E7889;
  text-align: center;
}

#about .about1 .container .gallery .item .bottom #ctaWpp {
  margin: auto auto 0 1.7vw;
  position: absolute;
  bottom: 1rem;
}

#about .about1 .container .gallery .item .bottom #ctaWpp p {
  font-size: .9rem;
}

#about .about2 .container {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  border-bottom: 1px solid #35406D;
}

#about .about2 .container .gallery {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

#about .about2 .container .gallery .top {
  display: flex;
  gap: .7rem;
}

#about .about2 .container .gallery .top .left {
  width: 100%;
}

#about .about2 .container .gallery .top .right {
  width: 71%;
}

#about .about2 .container .gallery .middle #ctaWpp {
  position: absolute;
  margin-top: -6rem;
  margin-left: 4.2rem;
}

#about .about2 .container .gallery .bottom {
  display: flex;
  gap: .7rem;
}

#about .about2 .container .gallery .bottom .left {
  width: 71%;
}

#about .about2 .container .gallery .bottom .right {
  width: 100%;
}

#about .about3 .container {
  display: flex;
  justify-content: center;
  gap: 2rem;
}

#about .about3 .container .left {
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2rem;
  margin-top: 1rem;
}

#about .about3 .container .left .title {
  text-align: left;
  width: 70%;
}

#about .about3 .container .left .text {
  color: #3D4C86;
  width: 70%;
}

#about .about3 .container .left #ctaWpp {
  margin: 0 auto;
  width: 70%;
}

#about .about3 .container .right {
  width: 50%;
  text-align: left;
}

#about .about3 .container .right img {
  width: 75%;
}

/* ==================== MEDIA QUERIES (MD) ==================== */
@media screen and (max-width: 1024px) {
  #about .about1 .container .gallery {
    grid-template-columns: 1fr;
    grid-template-rows: repeat(6, 1fr);
  }

  #about .about1 .container .gallery .item {
    padding-bottom: 0;
  }

  #about .about1 .container .gallery .item {
    padding-bottom: 4rem;
  }

  #about .about1 .container .gallery .item .bottom #ctaWpp {
    margin: auto auto 0 1.3rem;
    width: 90%;
  }

  #about .about2 .container .gallery .middle {
    width: 100%;
  }

  #about .about2 .container .gallery .middle #ctaWpp {
    position: absolute;
    margin-top: -5rem;
    margin-left: 2.3rem;
    width: 74%;
  }

  #about .about3 .container {
    flex-direction: column;
  }

  #about .about3 .container .left {
    margin-top: 0;
  }

  #about .about3 .container .left .title {
    width: 80%;
    text-align: center;
  }

  #about .about3 .container .left .text {
    width: 80%;
    text-align: center;
  }

  #about .about3 .container .left #ctaWpp {
    margin: 0 auto;
    width: 100%;
  }

  #about .about3 .container .left #ctaWpp figure {
    width: 6%;
  }

  #about .about3 .container .right {
    width: 100%;
    text-align: center;
    order: -1;
  }

  #about .about3 .container .right img {
    width: 30%;
  }
}

/* ==================== MEDIA QUERIES (SM) ==================== */
@media screen and (max-width: 600px) {
  #about .about1 .container .gallery .item .bottom {
    height: fit-content;
  }

  #about .about1 .container .gallery .item {
    padding-bottom: 5rem;
  }

  #about .about1 .container .gallery .item .bottom #ctaWpp {
    margin: auto auto 0 0rem;
    width: 95%;
  }


  #about .about2 .container .gallery .middle #ctaWpp {
    position: absolute;
    margin-top: -4rem;
    margin-left: .7rem;
    width: 90%;
  }

  #about .about2 .container .gallery .middle #ctaWpp figure {
    width: 4%;
  }
}